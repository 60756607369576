const userMenu = [
  {
    routeName: "Dashboard",
    link: "/dashboard",
  },
  {
    routeName: "Settings",
    link: "/settings",
  },
];

export default userMenu;
