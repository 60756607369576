const COLORS = {
  ERROR: "error",
  GRAY: "gray",
  PRIMARY_ALT: "primaryAlt",
  PRIMARY: "primary",
  SUCCESS: "success",
  WARNING: "warning",
  BLUE: "blue",
};

export default COLORS;
